<template>
    <div
        v-if="!isLoading"
        class="express-order"
    >
        <div class="wrapper">
            <order-steps />
        </div>
        <div class="bids-row">
            <div class="wrapper">
                <div class="row">
                    <div class="col-9">
                        <div class="bids-row__header">
                            <p class="bids-row__header_title">
                                Choose your expert
                            </p>
                            <sorting
                                :sort-by-fields="sortByFields"
                                :search-object="searchObject"
                                :sort-order-fields="sortOrderFields"
                                class="express-order__filter"
                                @updateSorting="updateSorting"
                            />
                        </div>
                        <radius-loader v-if="bidsLoading" />
                        <template v-else>
                            <order-express-loading
                                :results-count="bids.length"
                                :order-created-date="order.created_at"
                                :writers-counter="writersCounter"
                                :writers-online-count="writersOnlineCount"
                            />
                            <transition name="fade">
                                <div
                                    v-if="bids && bids.length > 0"
                                    class="bids-list"
                                >
                                    <transition-group
                                        name="list"
                                        tag="div"
                                    >
                                        <BidCard
                                            v-for="bid in bids"
                                            :key="bid.id"
                                            :data="bid"
                                            @hireWriter="hireWriter"
                                            @openModalReview="openModalReview"
                                        />
                                    </transition-group>
                                </div>
                            </transition>
                            <transition
                                v-if="bids.length === 0"
                                name="fade"
                            >
                                <div
                                    class="bids-list"
                                >
                                    <BidCard
                                        v-for="(bid, index) in dataForSkeleton"
                                        :key="index"
                                        :data="bid"
                                        skeleton
                                    />
                                </div>
                            </transition>
                        </template>
                    </div>
                    <div class="col-3">
                        <div class="bids-row_sidebar">
                            <support-banner />
                        </div>
                    </div>
                </div>
                <div class="bids-row__writers">
                    <TopWritersSlider />
                </div>
            </div>
        </div>
        <assigne-writer-modal
            ref="assigne-modal"
            :assigne-loading="assigneLoading"
        />
        <OrderBidsSnackbar :snackbar="bidsSnackbar" />
        <review-modal ref="review-modal" />
    </div>
</template>

<script>
import { eventBus } from '@/helpers/event-bus'
import Api from '@/helpers/api/index.js'

import BidCard from '@/components/account/BidCard.vue'
import RadiusLoader from '@/components/common/RadiusLoader.vue'
import OrderSteps from '@/components/account/orders/express/OrderSteps.vue'
import OrderExpressLoading from '@/components/account/orders/express/OrderExpressLoading.vue'
import OrderBidsSnackbar from '@/components/account/orders/express/OrderBidsSnackbar.vue'
import SupportBanner from '@/components/common/banners/SupportBanner.vue'
import AssigneWriterModal from '@/components/modals/AssigneWriterModal.vue'
import ReviewModal from '@/components/modals/ReviewModal';
import Sorting from '@/components/common/Sorting.vue'
import TopWritersSlider from '@/components/top-writers-slider/TopWritersSlider';
import ChangeTitle from '@/helpers/changeTitle.js'

import { onlineCount } from '@/services/writers-chat'

const file = require('@/assets/sounds/correct-answer.wav')

export default {
    components: {
        BidCard,
        RadiusLoader,
        OrderSteps,
        OrderExpressLoading,
        OrderBidsSnackbar,
        SupportBanner,
        AssigneWriterModal,
        ReviewModal,
        Sorting,
        TopWritersSlider
    },
    data() {
        return {
            isLoading: true,
            order: null,
            bids: null,
            bidsLoading: true,
            searchObject: {
                sort_by: 'relevant',
                sort_order: 'DESC'
            },
            writersCounter: {},
            bidsSnackbar: [],
            assigneLoading: false,
            isFirstLoad: true,
            writersOnlineCount: null
        }
    },
    computed: {
        sortByFields() {
            return [
                {
                    value: 'relevant',
                    text: 'Most relevant'
                },
                {
                    value: 'experienced',
                    text: 'Most experienced'
                },
                {
                    value: 'lowest_price',
                    text: 'Lowest price'
                }
            ]
        },
        sortOrderFields() {
            return [
                {
                    text: 'ASC',
                    value: 'ASC'
                },
                {
                    text: 'DESC',
                    value: 'DESC'
                }
            ]
        },
        dataForSkeleton() {
            const BidSkeleton = {
                id: 0,
                created_at: '2022-12-07T13:05:44.000000Z',
                updated_at: '2022-12-07T13:05:44.000000Z',
                due_at: '2021-12-09T12:29:33.000000Z',
                total: 25,
                writer: {
                    id: 5333,
                    name: 'test123456test',
                    avatar_url: 'https://cdn.freelancewriting.center/file/HF7iIirw9P3d3A6oWEf6Fyi8b4ZLw66U28V2jmWP.png',
                    avatar_fallback: '',
                    statistics: {
                        orders_total: 25,
                        rank_thirty_days: '8.00',
                        rank_thirty_days_count: 1,
                        rank_all: '8.00',
                        rank_all_count: 1
                    },
                    personal_statement: 'Personal statement Personal statement Personal statementPersonal statement Personal statement Personal statementPersonal statement Personal statement Personal statementPersonal statement Personal statement Personal statement'
                }
            }
            return [BidSkeleton]
        }
    },
    async created() {
        await this.getOrderData()
        await this.getListBids()
        await this.getWriterOnlineCount()
        this.$socket.emit('order:subscribe', this.$route.params.id)
        this.$socket.on('order:newBid', (data) => {
            this.bids.unshift({ ...data, isNew: true })
            setTimeout(() => {
                const bidIndex = this.bids.findIndex((bid) => bid.id === data.id)
                this.bids.splice(bidIndex, 1, { ...data, isNew: false });
            }, 1000 * 15);
            this.playSound()
            this.titleChange('New Bid 🔔')
        })
        this.$socket.on('order:updateBid', (data) => {
            const bidIndex = this.bids.findIndex((bid) => bid.id === data.id)
            this.bids.splice(bidIndex, 1, { ...data, isUpdated: true });

            setTimeout(() => {
                const bidIndex = this.bids.findIndex((bid) => bid.id === data.id)
                this.bids.splice(bidIndex, 1, { ...data, isUpdated: false });
            }, 1000 * 15);
            this.playSound()
            this.titleChange('Updated Bid 🔔')
        })
        this.$socket.on('order:cancelBid', (data) => {
            this.bids = this.bids.map((bid) => {
                if (bid.id === data.id) {
                    return {
                        ...bid,
                        isUpdated: false,
                        isNew: false,
                        canceled: true
                    }
                }
                return bid
            })
            this.playSound()

            setTimeout(() => {
                this.bids = this.bids.filter((bid) => bid.id !== data.id)
            }, 1000 * 15);
        });
        this.$socket.on('order:writersCounter', (data) => {
            this.writersCounter = data
        })
        this.$socket.on('order:writerView', (data) => {
            const findIndex = this.bidsSnackbar.findIndex((item) => item.sw_id === data.sw_id)
            if (findIndex === -1) {
                this.bidsSnackbar = [...this.bidsSnackbar, data]
                setTimeout(() => {
                    this.bidsSnackbar.shift()
                }, 10000);
            }
        })

        this.$socket.on('disconnect', () => {
            this.$socket.emit('order:subscribe', this.$route.params.id)
        });
    },
    beforeDestroy() {
        this.$socket.emit('order:unsubscribe', this.$route.params.id)
    },
    deactivated() {
        this.$socket.emit('order:unsubscribe', this.$route.params.id)
    },
    methods: {
        async getWriterOnlineCount() {
            try {
                this.isLoading = true
                this.writersOnlineCount = await onlineCount()
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error');
            } finally {
                this.isLoading = false
            }
        },
        async getOrderData() {
            this.isLoading = true
            try {
                const { data } = await Api.get('/api/order/fetch', { orderid: this.$route.params.id })
                this.order = data
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.isLoading = false
            }
        },
        async getListBids() {
            this.bidsLoading = true
            try {
                const { data } = await Api.get('/api/order/checkout/express/list-bids', { ...this.searchObject, orderid: this.$route.params.id })
                this.bids = data.bids
                this.isFirstLoad = false
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.bidsLoading = false
            }
        },
        async hireWriter(id) {
            this.$refs['assigne-modal'].open()
                .then(async (res) => {
                    if (res) {
                        try {
                            this.assigneLoading = true
                            await Api.post('/api/order/checkout/express/accept-bid', { orderid: this.$route.params.id, id })
                            this.$router.push({ name: 'checkout' })
                        } catch (error) {
                            eventBus.$emit('showSnackBar', error, 'error')
                        } finally {
                            this.assigneLoading = false
                            this.$refs['assigne-modal'].close()
                        }
                    }
                })
        },
        async updateSorting(payload) {
            if (!this.isFirstLoad) {
                this.searchObject = {
                    ...this.searchObject,
                    page: 1,
                    ...payload
                }
                this.bids = null
                await this.getListBids()
            }
        },
        playSound() {
            this.showMessenger = true
            const snd = new Audio(file);
            snd.play();
        },
        openModalReview(writerId) {
            this.$refs['review-modal'].open(writerId)
        },
        titleChange(title) {
            ChangeTitle.start(title)
        }
    }
}
</script>

<style lang="scss" scoped>
    .list-item {
        display: inline-block;
        margin-right: 10px;
    }
    .list-enter-active, .list-leave-active {
        transition: all 1s;
    }
    .list-enter, .list-leave-to {
        opacity: 0;
        transform: translateY(30px);
    }
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to {
        opacity: 0;
    }
</style>
